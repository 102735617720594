import axios from "axios";
import qs from "qs";

export class HubspotController {
  // START Tickets
  getTicket = async (id: string) => {
    const properties = [
      "hs_pipeline_stage",
      "hs_pipeline",
      "notti_pernottamento",
      "num_persone",
      "subject",
      "servizi_richiesti",
      "data_visita",
      "chilometri",
      "data_intervento",
      "diritto_chiamata_riscosso",
      "ore_di_lavoro",
      "ore_viaggio",
      "note_intervento",
      "articolo_1",
      "qta_articolo_1",
      "articolo_2",
      "qta_articolo_2",
      "articolo_3",
      "qta_articolo_3",
      "articolo_4",
      "qta_articolo_4",
      "rapportino",
      "foto_del_prodotto",
      "customer_order_number"
    ];

    const associations = ["contacts"];

    const response = await axios(
      "https://a09wzninrk.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/tickets/" +
      id,
      {
        params: {
          properties: properties.join(","),
          associations: associations.join(","),
        },
        paramsSerializer: (params: any) => {
          return qs.stringify(params);
        },
      }
    );

    return response.data;
  };

  createTicket = async (ticket: any, contact: any) => {
    const objDate = new Date(ticket.data_visita);
    const response = await axios(
      "https://a09wzninrk.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/tickets",
      {
        method: "post",
        data: {
          hs_pipeline_stage: ticket.hs_pipeline_stage,
          hs_pipeline: ticket.hs_pipeline,
          notti_pernottamento: ticket.notti_pernottamento,
          num_persone: ticket.num_persone,
          subject: ticket.subject
            ? `${ticket.subject} - ${objDate.getDate()}/${objDate.getMonth() + 1 > 9 ? objDate.getMonth() + 1 : "0" + (objDate.getMonth() + 1)
            }/${objDate.getFullYear()}`
            : `Visita ${contact.firstname} ${contact.lastname
            } - ${objDate.getDate()}/${objDate.getMonth() + 1
            }/${objDate.getFullYear()}`,
          servizi_richiesti: ticket.servizi_richiesti.join(";"),
          data_visita: ticket.data_visita,
          arrival___departure: ticket.arrival___departure,
          date_and_duration_of_visit: ticket.date_and_duration_of_visit,
          date_of_diner: ticket.date_of_diner,
          date_of_lunch: ticket.date_of_lunch,
          agente: ticket.agente,
          country: contact.nazioni_new
        },
      }
    );

    return response.data;
  };

  enrichTicketInfo = async (ticketId: any, payload: any) => {
    const response = await axios(
      `https://a09wzninrk.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/tickets/${ticketId}`,
      {
        method: "patch",
        data: {
          customer_order_number: payload.customer_order_number
        },
      }
    );

    return response.data;
  };

  async associate(ticketId: string, contactId: string) {
    return await axios(
      `https://a09wzninrk.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/tickets/${ticketId}/associate`,
      {
        method: "post",
        data: {
          toObjId: contactId,
          objType: "contacts",
          type: "ticket_to_contact",
        },
      }
    );
  }
  // END Tickets

  // START Contacts
  createContact = async (contact: any, ticket: any) => {
    const contact_payload = this.sanitizePayload(contact)
    delete contact_payload["properties"]
    delete contact_payload["createdAt"]
    delete contact_payload["updatedAt"]
    delete contact_payload["archived"]
    const response = await axios(
      "https://a09wzninrk.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/contacts/addOrUpdate",
      {
        method: "post",
        data: {
          ...contact_payload,
          notti_pernottamento: ticket.notti_pernottamento,
        },
      }
    );

    return response.data;
  };
  // END Contacts

  // START Properties
  listProperties = async (type: string) => {
    return await axios(
      `https://a09wzninrk.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/properties/${type}`
    );
  };

  getProperty = async (type: string, id: string) => {
    const response = await axios(
      `https://a09wzninrk.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/properties/${type}/${id}`
    );

    return response.data;
  };
  // END Properties

  sanitizePayload = (object: any) => {
    const payload: any = {};
    const exclude = [
      'id'
    ]

    Object.keys(object).forEach((k) => {
      if (!k.startsWith("hs_") && !exclude.includes(k)) {
        payload[k] = object[k];
      }
    });

    return payload
  }
}
